import React, { useEffect } from 'react';
import styledComponents from 'styled-components';
import { Element } from 'react-scroll'; 
import { Navigation } from '../components/navigation';
import { Marginer } from '../components/marginer';
import { Footer } from '../components/footer';
import { MobileNav } from '../components/mobileNav';
import { BreakingPoint } from '../components/breakingPoint';




const Container=styledComponents.div`
display:flex;
flex-direction:column;
`

export function RefundCancellationPage() {
 
  const{isMobile}=BreakingPoint();




  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      {isMobile ? (
        <MobileNav />
      ) : (
        <Navigation color="#25D366" background="grey" />
      )}
      <div style={{ padding: '20px', maxWidth: '800px', margin: 'auto', fontFamily: 'Arial, sans-serif' }}>
        <h1>Refund and Cancellation Policy</h1>
        <p>
          We understand that sometimes plans change. Below is our policy outlining how cancellations and refunds are processed:
        </p>
        <h2>Cancellation Timeframes</h2>
        <ul>
          <li>
            <strong>More than 7 days before check-in:</strong> Fully refundable.
          </li>
          <li>
            <strong>3 to 7 days before check-in:</strong> 50% refund.
          </li>
          <li>
            <strong>Less than 3 days before check-in:</strong> No refunds.
          </li>
        </ul>
        <h2>Non-Refundable Rates</h2>
        <p>
          Certain discounted or promotional rates are non-refundable, regardless of the timeframe.
        </p>
        <h2>How to Request a Cancellation</h2>
        <p>
          Please contact us at <a href="mailto:reservations@diamondshotelkampala.com">reservations@diamondshotelkampala.com</a> or call +256 755 956 523.
          Include your booking confirmation number and the name on the reservation. Refunds, if applicable, will be
          processed within 7-10 business days.
        </p>
        <h2>Changes to This Policy</h2>
        <p>
          We reserve the right to modify this policy at any time. Changes take effect immediately upon posting.
        </p>
      </div>
      <Marginer direction="vertical" margin="6em" />
      <Element name="footer">
        <Footer />
      </Element>
    </Container>
  );
}