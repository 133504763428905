import React, { useState } from "react";
import styledComponents from "styled-components";
import "rc-steps/assets/index.css";
import Steps, { Step } from "rc-steps";
import { Card, CardBody, Input, Label, Row, Col, FormGroup,Button, Spinner } from "reactstrap";
import { Marginer } from "../marginer";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import {useNavigate, useLocation} from 'react-router-dom';
import moment from "moment";
/*******************styles**************************/
const ReservationContainer = styledComponents.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  `;
const DescText = styledComponents.p`
  font-size:16px;
  font-family: 'Poppins', sans-serif;
  font-weight:500;
  color:black;
  `;
const RoomDesc = styledComponents.p`
font-size:18px;
  font-family: 'Poppins', sans-serif;
  font-weight:500;
  color:black;
`;

const FullInput = styledComponents.div`
margin-bottom:.5em;
margin-left:1em;
margin-right:1em;
margin-top:.5em;
padding: 0em 2em;

`;
const StepContainer = styledComponents.div`
  display:flex;
  flex-direction:row;
  width:80%;
  justify-content:center;
  flex-wrap:wrap;
  @media screen and (max-width: 480px){
    width:90%;
  }
  `;

const DateCard = styledComponents(Card)`
display:flex;
flex-direction:row;
flex-wrap:true;
justify-self:center;
width:85%;
@media screen and (max-width: 480px){
  flex-direction:column;
}
`;

const Reciept = styledComponents(Card)`
display:flex;
flex-direction:row;
width:80%;
@media screen and (max-width: 480px){
  flex-direction:column;
}

`;

const CustomerInfoCard = styledComponents(Card)`
display:flex;
flex-direction:row;
width:80%;
height:auto;
@media screen and (max-width: 480px){
  flex-direction:column;
}
`;

const StyInput = styledComponents(Input)`
border:solid black 1px;
width:324px;
height:38px;
@media screen and (max-width: 480px){
  width:200px;
  height:auto;
}
`;

const ConfrimBN=styledComponents(Button)`
font-size:16px;
font-family: 'Poppins', sans-serif;
font-weight:500;
color:whitesmoke;
background-color:black;
margin-left:35%;
width:25%;
margin-top:2em;
@media screen and (max-width: 480px){
  width:auto;
  margin-left:4em;
  margin-bottom:3em;
  
}
`

const StyledSpinner=styledComponents(Spinner)`
color:black;
background-color:white;
margin-left:35%;
align-self:center;
width:auto;
margin-top:2em;
@media screen and (max-width: 480px){
  width:auto;
  
 // margin-left:4em;
  margin-bottom:3em;
  
}


`
const StyledHeader=styledComponents.h1`
font-size:30px;
font-family: 'Poppins', sans-serif;
font-weight:500;
color:black;
margin-left:25%;
margin-top:2%;
@media screen and (max-width: 480px){
margin-left:22.5%;
margin-top:2%;

}


`

const Stytitle = styledComponents.p`
font-size:16px;
font-family: 'Poppins', sans-serif;
font-weight:500;
color:black;

`;

/*******************styles**************************/

const Schema=yup.object().shape(
    {
        firstName:yup.string().required("first name is  required"),
        lastName:yup.string().required("last name is required"),
        email:yup.string().email().required("email is required"),
        phoneNumber:yup.number().required().typeError("phone number is reqired")
    });

export function Reservation() {
 

    const { handleSubmit, control, formState: { errors },} = useForm({
        resolver: yupResolver(Schema),
    });
    const location=useLocation();
    const checkIn=location.state.checkIn;
    const checkOut=location.state.checkOut;
    const numOfRooms=location.state.numOfRooms;
    const roomType=location.state.roomType;
    const checkInConv=checkIn.toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"});
    const checkOutConv=checkOut.toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"});  
    const enumArr=["Single Room","Deluxe Double Room","Standard Double Room","Twin Room"]
    const [loading,setLoading]=useState(false); 
  const onSubmit = (data) =>{

    const element= {
      checkInDate:moment(checkIn).format("YYYY-MM-DD"),
      checkOutDate:moment(checkOut).format("YYYY-MM-DD"),
      room:roomType,
      customer:{
          firstName:data.firstName,
          lastName:data.lastName,
          email:data.email,
          phoneNumber:data.phoneNumber
      }
    }
   
    setLoading(true);
    axios.post('https://diamondbackend.herokuapp.com/api/Book',element).then(response =>{
      if(response.status===201){
        setLoading(false);
        alert("successfully booked")
      }}
    ).catch(err=>{setLoading(false);alert("there was a problem please try again")})
    
  };
  
  
  
  
  
  
  
  return (
    <ReservationContainer>
      <StepContainer>
        <Steps current={2}>
          <Step title={<Stytitle>Dates</Stytitle>} description="" />
          <Step title={<Stytitle>Rooms</Stytitle>} description="" />
          <Step title={<Stytitle>Book</Stytitle>} description="" />
        </Steps>
      </StepContainer>
      <DateCard>
        <CardBody>
          <DescText>
            Check In Date <br />
            {checkInConv}
          </DescText>
        </CardBody>
        <CardBody>
          <DescText>
            Check out Date <br />
            {checkOutConv}
          </DescText>
        </CardBody>
        <CardBody>
          <DescText>
            number of rooms <br />{numOfRooms}
          </DescText>
        </CardBody>
      </DateCard>
      <Marginer direction="vertical" margin="2em" />
      <Reciept>
        <CardBody>
          <RoomDesc>
            Room Types
            <br />
            {
            roomType.map((i)=>(  
            <>
            {enumArr[i]}
            <br/>
            </>
            ))
            
          }
            
            
            
          </RoomDesc>
        </CardBody>
        <CardBody>
          <RoomDesc>
            Total price <br />
            {30*numOfRooms}
          </RoomDesc>
        </CardBody>
      </Reciept>
      <Marginer direction="vertical" margin="2em" />
      <CustomerInfoCard>
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledHeader>Customer Information</StyledHeader>
            <Row>
             <Col md={6} xs={12}>   
            <FormGroup>
          <FullInput>
            <Label>*First Name</Label>
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => <StyInput {...field} />}
            />
            <p>{errors.firstName?.message}</p>
          </FullInput>
          <FullInput>
            <Label>*Last Name</Label>
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => <StyInput {...field} />}
            />
            <p>{errors.lastName?.message}</p>
          </FullInput>
          </FormGroup>
          </Col>
          <Col md={6} xs={12}>
          <FullInput>
            <Label>*Email</Label>
            <Controller
              name="email"
              control={control}
              render={({ field }) => <StyInput {...field} />}
            />
            <p>{errors.email?.message}</p>
          </FullInput>

          <FullInput>
            <Label>*Phone Number</Label>
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => <StyInput {...field} />}
            />
            <p>{errors.phoneNumber?.message}</p>
          </FullInput>
          </Col>
          {loading?(<StyledSpinner size="lg"/>):
          (<ConfrimBN onClick={handleSubmit(onSubmit)}>Confirm reservations</ConfrimBN>)
          }
          </Row>
          <h5>*Payment is made on arrival</h5>
        </form>
      </CustomerInfoCard>
    </ReservationContainer>
  );
}
