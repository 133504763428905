import './App.css';
import React from 'react';
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import {Homepage,RoomDetailsPage,BookStepPage,BookPage,RefundCancellationPage} from "./pages";



function App() {
  return (
    <>  
    <Router>
      <Routes>
        <Route path='/' exact={true} element={<Homepage/>} />
        <Route path='Rooms' exact={true} element={<RoomDetailsPage/>} />
        <Route path='/Select' exact={true} element={<BookStepPage/>} />
        <Route path='/Reserve' exact={true} element={<BookPage/>} />
        <Route path='/refundPolicy' exact={true} element={<RefundCancellationPage/>} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
