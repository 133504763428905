import { useEffect } from "react";
import styledComponents from 'styled-components';
import { Navigation } from '../components/navigation';
import DiamondLogo from '../assets/DiamondLogo.svg'
import { Marginer } from '../components/marginer';
import { BookStep } from '../components/bookStep';
import { Footer } from '../components/footer';
import { Element} from "react-scroll";
import { BreakingPoint } from '../components/breakingPoint';
import { MobileNav } from "../components/mobileNav";

/*****************************Styles********** */
const Container=styledComponents.div`
display:flex;
flex-direction:column;
`
const LogoContainer=styledComponents.div`
display:flex;
flex-direction:row;
justify-content:center;
background-color:#abb8c3;
`
const LogoInner=styledComponents.div`
display:flex;
flex-direction:column;
`
const Logo=styledComponents.img`
width:100px;
height:100px;
margin-top:.5em;
margin-left:1em;
`
const LogoText=styledComponents.h3`
font-family: 'Poppins', sans-serif;
font-size:20px;
font-weight:800;

`

/*****************************Styles********** */


export function BookStepPage(){
    
    const{isMobile}=BreakingPoint();

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return(
    <Container>
    {isMobile?(<MobileNav/>):(<Navigation color="black" background="white"/>)}
    <LogoContainer>
    <LogoInner>
    <Logo src={DiamondLogo}></Logo>
    <LogoText>Diamonds hotel</LogoText>
    </LogoInner>
    </LogoContainer>
    <Marginer direction="vertical" margin="3em"/>
    <BookStep/>
    <Marginer direction="vertical" margin="6em"/>
    <Element name='footer'>
        <Footer/> 
        </Element>
    </Container>
    );
}