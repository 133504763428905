import { Button } from "reactstrap";
import React from 'react';
import styledComponents from 'styled-components';
import DiamondLogo from '../../assets/DiamondLogo.svg';
import { scroller } from 'react-scroll';



/**************Style ******************/
const NavContainer=styledComponents.div`
display:flex;
flex-direction:row;
width:100%;
justify-content:space-between;
background:${({background})=>`${background}`};

`

const StyHeader=styledComponents.h1`
font-size:30px;
color:${({color})=>`${color}`};
margin-top:1em;
margin:.5em;
`
const MenuContainer=styledComponents.div`
display:flex;
flex-direction:row;
margin-top:1.5em;
margin-right:3em;
pading-bottom:1em;
`

const Logo=styledComponents.img`
width:50px;
height:50px;
margin-top:1em;
margin-left:1em;
justify-self:start;
`

const LogoContainer=styledComponents.div`
display:flex;
flex-direction:row;

`

const StyledLink=styledComponents.li`
margin:1em;
list-style: none;
`

const StyledA=styledComponents.a`
font-size:17px;
color:whitesmoke;
text-decoration:none;
color:${({color})=>`${color}`};
`

/**************Style ******************/






export function Navigation(props){

    const {background,color} = props;
    const scrollToNext=()=>{
        scroller.scrollTo("footer",{smooth:false,duration:500});
    
      }



return(
    <NavContainer background={background}>
        <LogoContainer>
        <Logo src={DiamondLogo}></Logo>
        <StyHeader color={color}>Diamonds Hotel</StyHeader>
        </LogoContainer>
        <MenuContainer>
        <StyledLink>
            <StyledA href='/' color={color}>Home</StyledA>
        </StyledLink>
        <StyledLink>
            <StyledA href='/Rooms' color={color}>Rooms</StyledA>
        </StyledLink>
        <StyledLink>
            <StyledA color={color} onClick={scrollToNext}>Contact</StyledA>
        </StyledLink>
        </MenuContainer>
    </NavContainer>
)


}