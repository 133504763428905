import React from "react";
import styledComponents from "styled-components";
import { Card, CardBody, Label, Input, Button } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { Marginer } from "../marginer";
import DL from "../../assets/Dl.jpg";
import {useNavigate} from 'react-router-dom';
import {BreakingPoint} from '../breakingPoint';

/********************style*****************************/

const MidContainer = styledComponents.div`
width:100%;
min-height:800px;
display:flex;
flex-direction:column;
background-color:whitesmoke;
@media screen and (max-width: 480px){

}
`;
const StyForm = styledComponents.form`
display:flex;
justify-content: space-around;
@media screen and (max-width: 480px){
  flex-direction:column;
}

`;
const StyledDatePicker = styledComponents(DateRangePicker)`
height:5em;
@media screen and (max-width: 480px){
  height:2.5em;
  width:100%;
}
`;

const FormSect = styledComponents.div`
display:block;
`;

const FormSect2 = styledComponents.div`
display:block;
`;

const StyledSelect = styledComponents(Input)`
height:4.5em;
font-size:19px;
@media screen and (max-width: 480px){
  height:auto;
}
`;

const StyledButton = styledComponents(Button)`
color:whitesmoke;
background-color:black;
height:3em;
margin-right:10%;
align-self:center;
@media screen and (max-width: 480px){
  margin-top:2em;
}
`;
const Stytitle = styledComponents.h1`
font-family: 'Poppins', sans-serif;
font-size:40px;
font-weight:800;
margin:.25em;
@media screen and (max-width: 480px){
  margin-left:2em;
  font-weight:500;
  font-size:30px;
}
`;
const Styp = styledComponents.p`
font-family: 'Poppins', sans-serif;
font-size:18px;
font-weight:400;
margin:.25em;
@media screen and (max-width: 480px){
  font-weight:400;
  font-size:15px;
  width:100%;
  justify-self:center;

}
`;
const StyImg1 = styledComponents.img`
width:504px;
height:504px;



`;

const StyCard = styledComponents(Card)`
height:150px;
display:flex;
flex-direction:column;
border-style:outset;
width:65%;
margin-top:10%;
align-self:center;
@media screen and (max-width: 480px){
height:auto;
width:90%;

}
`;
const StyDivContainer = styledComponents.div`
display:flex;
flex-direction:row;
width:100%;
justify-content:space-around;
@media screen and (max-width: 480px){
  flex-direction:column;
}



`;
const StyMap=styledComponents(Card)`
margin-top:1em;
width:504px;
height:310px;
@media screen and (max-width: 480px){
  width:330px;
  height:310px;
  align-self:center
}


`

const StyDiv = styledComponents.div`
display:flex;
flex-direction: column;
@media screen and (max-width: 480px){
  margin:.25em;
}

`;

/********************style*****************************/

export function MidSection() {
  const { control, handleSubmit } = useForm();
  const navigate=useNavigate();
  const {isMobile}=BreakingPoint();

  const onSubmit = (data) => {
    navigate("/Select",{state:{checkIn:data.checkIn,numOfRooms:data.numOfRooms}})
    
  };

  return (
    <MidContainer>
      <StyCard>
        <CardBody>
          <StyForm onSubmit={handleSubmit(onSubmit)}>
            <FormSect>
              <Label>Check in Date - Check out Date</Label>
              <br></br>
              <Controller
                name="checkIn"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <StyledDatePicker
                    {...field}
                    minDate={new Date()}
                    onChange={(e) => field.onChange(e)}
                    selected={field.value}
                  />
                )}
              />
            </FormSect>
            <FormSect2>
              <Label> Number of Rooms</Label>
              <Controller
                name="numOfRooms"
                control={control}
                rules={{ required: true }}
                defaultValue={1}
                render={({ field }) => (
                  <StyledSelect {...field} type="select">
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                  </StyledSelect>
                )}
              />
            </FormSect2>
            <StyledButton>Book Now</StyledButton>
          </StyForm>
        </CardBody>
      </StyCard>
      <Marginer direction="vertical" margin="4em" />
      <StyDivContainer>
        <StyDiv isReversed={false}>
          <StyImg1 src={DL} />
        </StyDiv>
        <StyDiv isReversed={true}>
        {isMobile && <Marginer direction="vertical" margin="2em"/>} 
          <Stytitle>Expirience kampala</Stytitle>
          <Styp>
            Diamonds Hotel is located in Kampala. Uganda National {!isMobile && <br />}
            Museum and Ndere Cultural Centre are cultural
            {!isMobile && <br />}
            highlights, and some of the area's landmarks include
            {!isMobile && <br />}
            Kabaka's Palace and Kasubi Tombs.
          </Styp>
          {isMobile && <Marginer direction="vertical" margin="2em"/>} 
          <StyMap>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15959.035122458816!2d32.55976974964143!3d0.3130149171996344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x177dbc813c11af61%3A0x84a8365c4aec84d1!2sDiamonds%20Hotel!5e0!3m2!1sen!2sus!4v1654785337502!5m2!1sen!2sus" width="100%" height="100%" style={{border:"0"}} allowfullscreen="" 
loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </StyMap>
        </StyDiv>
      </StyDivContainer>

      {!isMobile && <Marginer direction="vertical" margin="4em" />}
    </MidContainer>
  );
}
