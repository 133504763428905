import { useEffect } from "react";
import styledComponents from 'styled-components';
import { RoomDetails } from '../components/roomDetails';
import { Navigation } from '../components/navigation';
import { Marginer } from '../components/marginer';
import { Footer } from '../components/footer';
import { Element} from "react-scroll";
import { MobileNav } from '../components/mobileNav';
import { BreakingPoint } from '../components/breakingPoint';


/*****************Styles*****************/

const Container=styledComponents.div`
display:flex;
flex-direction:column;

`

/*****************Styles*****************/


export function RoomDetailsPage(){

    const{isMobile}=BreakingPoint();

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return(
        <Container>
        {isMobile?(<MobileNav/>):(<Navigation color="#25D366" background="grey"/>)} 
        <RoomDetails/>
        <Marginer direction="vertical" margin="6em"/>
        <Element name='footer'>
        <Footer/> 
        </Element>
        </Container>
    );
}