import React from "react";
import styledComponents from "styled-components";
import { Button, Card, CardBody, UncontrolledCollapse } from "reactstrap";
import NavIcon from "../../assets/NavIcon.svg";
import DiamondLogo from "../../assets/DiamondLogo.svg";
import { scroller } from "react-scroll";
//*************************Styles*********************/
const NavIco = styledComponents.img`
width:20px;
height:20px;
`;
const StyButton = styledComponents(Button)`
position:absolute;
right:.25em;
top:2em;
`;

const Logo = styledComponents.img`
width:50px;
height:50px;
margin:1em 1em;
justify-self:start;
`;
const LinkHolder=styledComponents(CardBody)`
display:flex;
flex-direction:row;
justify-content:center;
width:22.25em;

`
const MenuContainer=styledComponents(Card)`
border:solid 1px black;
width:150%;


`

const StyledA = styledComponents.a`
font-size:17px;
color:black;
text-decoration:none;
`;
const Container = styledComponents.div`
display:flex;
flex-direction:row;
flex-wrap:wrap;
background-color:whitesmoke;
`;
const StyHeader = styledComponents.h1`
font-size:28px;
color:#25D366;
margin-top:1em;
margin:.25em;
`;

//*************************Styles*********************/

export function MobileNav() {
  const scrollToNext = () => {
    scroller.scrollTo("footer", { smooth: false, duration: 500 });
  };

  return (
    <Container>
      <Logo src={DiamondLogo} />
      <StyHeader>Diamonds Hotel</StyHeader>

      <StyButton outline={true} id="toggler">
        <NavIco src={NavIcon} />
      </StyButton>

      <UncontrolledCollapse toggler="#toggler">
        <MenuContainer>
          <LinkHolder>
            <StyledA href="/">Home</StyledA>
          </LinkHolder>
          <LinkHolder>
            <StyledA href="/Rooms">Rooms</StyledA>
            </LinkHolder>

            <LinkHolder>
            <StyledA onClick={scrollToNext}>Contact</StyledA>
          </LinkHolder>
        </MenuContainer>
      </UncontrolledCollapse>
    </Container>
  );
}
