import React from 'react';
import styledComponents from 'styled-components';



//*****************Style********************/
const  FooterContainer=styledComponents.div`
display:flex;
position:relative;
flex-direction:row;
background-color: #1f1f1f;
min-height:400px;
justify-content:space-around;
@media screen and (max-width: 480px){
flex-direction:column;
height:55em;
align-items:center;
justify-content:start;

}
`
const RightsReserved = styledComponents.div`
  position:absolute;
  bottom: 8px;
  align-self:center;
  color: whitesmoke;
  font-size: 12px;
  @media screen and (max-width: 480px){
      position:absolute;
      bottom:34em;
      align-self:center;
      color:whitesmoke;
      font-size:10px;
  } 
`;
const Divisions=styledComponents.div`
display:flex;
flex-direction:column;
color:whitesmoke;
margin-top:4em;
@media screen and (max-width: 480px){
    margin-top:0em;
    width:350px;
}
`
const Headers=styledComponents.h3`
font-family: 'Poppins', sans-serif;
font-weight:500;
@media screen and (max-width: 480px){
    font-size:22.4px;
    margin-top:2.5em;
    margin-bottom:1.5em;
    align-self:center;
}
`
const Subtext=styledComponents.a`
font-family: 'Poppins', sans-serif;
font-weight:300;
font-size:22px;
margin:8px;
text-decoration:none;
&:hover{
    text-decoration:underline;
}
@media screen and (max-width: 480px){
    font-size:15px;
    align-self:center;
}
`
const ContactText=styledComponents.p`
font-family: 'Poppins', sans-serif;
font-weight:500;
font-size:20px;
margin:8px;
@media screen and (max-width: 480px){
    font-size:15px;
    margin:0px;
    align-self:center;
    margin-left:3em;
}
`





//*****************Style********************/


export function Footer(){



    return(
        <FooterContainer>
        <Divisions>
            <Headers>Hotel</Headers>
            <Subtext href='/rooms'>rooms</Subtext>
            <Subtext href='/refundPolicy'>Refund Cancellation Policy</Subtext>
            <Subtext href='https://goo.gl/maps/MRpwSKiwJt4rfGHJA'>get directions</Subtext>             
        </Divisions> 
        <Divisions>
            <Headers>Contact</Headers>
            <ContactText>Diamond hotel kampala<br/>Phone numbers:<br/>+256 755 956 523 <br/>+256 778 907 480<br/>
            Email:<br/>Diamondshotelmilli@gmail.com</ContactText>             
        </Divisions>  
        <RightsReserved>&copy; 2022 Diamonds Hotel All rights reserved powered by nihilo</RightsReserved>
        </FooterContainer>
        
    );


} 