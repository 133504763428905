import { useEffect } from "react";
import styledComponents from 'styled-components';
import { Element} from "react-scroll";
import {Bookingsection} from '../components/bookingsection';
import {MidSection} from '../components/midsection';
import {Marginer} from '../components/marginer';
import {Gallery} from '../components/gallery';
import {Footer} from '../components/footer';
import { BreakingPoint } from '../components/breakingPoint';


/*******************Styles********/
const Container=styledComponents.div`
display:flex;
flex-direction:column;


`

export function Homepage(){
    const{isMobile}=BreakingPoint();

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return(
        <Container>
         <Element name="topSection">
         <Bookingsection/>  
        </Element>
        <Element name="bookSection">    
        <MidSection/>
        </Element>
        <Marginer direction="vertical" margin="3em"/>
        <Gallery/>
        {isMobile?(<Marginer direction="vertical" margin="1em"/>):(<Marginer direction="vertical" margin="15em"/>)}
        <Element name="footer">
        <Footer/>
        </Element>
        </Container>
    );
}