import { Button } from 'reactstrap';
import React from 'react';
import styledComponents from 'styled-components';
import Background from '../../assets/Background.jpg'
import { Navigation } from '../navigation';
import { scroller } from 'react-scroll';
import { BreakingPoint } from '../breakingPoint';
import { MobileNav } from '../mobileNav';


/***********************style ****************************************/
const BookingContainer=styledComponents.div`
background-image:url(${Background});
width:100%;
height:100vh;
background-repeat:no-repeat;
background-size:cover;
`;

const BookBt=styledComponents(Button)`
width:10%;
align-self:center;
margin-top:3em;
color:black;
background-color:whitesmoke;
@media screen and (max-width: 480px){
width:45%;
margin-top:1em;

}
`;

const BackgroundFilter = styledComponents.div`
  width: 100%;
  height: 100%;
  background-color:rgba(55, 55, 55, 0.40);
  display: flex;
  flex-direction: column;
`;

const StyH2=styledComponents.h2`
color:#25D366;
align-self:center;
font-family: 'Poppins', sans-serif;
font-weight:400;
font-size:400%;
line-height:50%;
margin-top:15%;
@media screen and (max-width: 480px){
font-size:2.5em; 
margin-top:55%;


}



`;
const StyP=styledComponents.p`
color:#25D366;
align-self:center;
font-size:150%;
font-family: 'Poppins', sans-serif;
font-weight:500;
@media screen and (max-width: 480px){
font-size:1em;

}



`;


/***********************style ****************************************/

export function Bookingsection(){
    
    const scrollToNext=()=>{
        scroller.scrollTo("bookSection",{smooth:false,duration:500});
    
      }

    const {isMobile}=BreakingPoint();
    return(
        <BookingContainer>
            <BackgroundFilter>
            {isMobile?(<MobileNav/>):(<Navigation color="#25D366"/>)}  
            <StyH2>Diamonds Hotel</StyH2>
            <StyP>In the heart of the city</StyP>
            <BookBt onClick={scrollToNext} outline={true}>Book now</BookBt>  
            </BackgroundFilter>
        </BookingContainer>
    )





}