import { useState } from "react";
import styledComponents from "styled-components";
import "rc-steps/assets/index.css";
import Steps, { Step } from "rc-steps";
import { Card, CardBody, Button, CardTitle } from "reactstrap";
import {useNavigate, useLocation} from 'react-router-dom';
import { Marginer } from "../marginer";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import sg1 from "../../assets/sg1.webp";
import sg2 from "../../assets/sg2.webp";
import sg3 from "../../assets/sg3.webp";
import sg4 from "../../assets/sg4.webp";
import sg5 from "../../assets/sg5.webp";
import sg6 from "../../assets/sg6.webp";
import sg7 from "../../assets/sg7.webp";
import sg8 from "../../assets/sg8.webp";
import sg9 from "../../assets/sg9.webp";
import tr1 from "../../assets/tr1.webp";
import tr2 from "../../assets/tr2.webp";
import tr3 from "../../assets/tr3.webp";
import tr4 from "../../assets/tr4.webp";
import tr5 from "../../assets/tr5.webp";
import tr6 from "../../assets/tr6.webp";
import sdr1 from "../../assets/sdr1.webp";
import sdr2 from "../../assets/sdr2.webp";
import sdr3 from "../../assets/sdr3.webp";
import sdr4 from "../../assets/sdr4.webp";
import sdr5 from "../../assets/sdr5.webp";
import ddr1 from "../../assets/ddr1.webp";
import ddr2 from "../../assets/ddr2.webp";
import ddr3 from "../../assets/ddr3.webp";
import ddr4 from "../../assets/ddr4.webp";
import ddr5 from "../../assets/ddr5.webp";
import ddr6 from "../../assets/ddr6.webp";
import ddr7 from "../../assets/ddr7.jpg";
import ddr8 from "../../assets/ddr8.webp";
import ddr9 from "../../assets/ddr9.webp";
import ddr10 from "../../assets/ddr10.webp";
import { Element,scroller } from "react-scroll";

/******************styles***************************** */

const StepContainer = styledComponents.div`
  display:flex;
  flex-direction:row;
  width:80%;
  justify-content:center;
  flex-wrap:wrap;
  @media screen and (max-width: 480px){
    width:90%;
  }
  `;

const Stytitle = styledComponents.p`
font-size:16px;
font-family: 'Poppins', sans-serif;
font-weight:500;
color:black;

`;
const DateCard = styledComponents(Card)`
display:flex;
flex-direction:row;
flex-wrap:true;
justify-self:center;
width:85%;
@media screen and (max-width: 480px){
  flex-direction:column;
}
`;
const DescText = styledComponents.p`
font-size:16px;
font-family: 'Poppins', sans-serif;
font-weight:500;
color:black;
`;

const RoomCard=styledComponents(Card)`
display:flex;
flex-direction:row;
min-width:85%;
height:auto;
margin:1em;
@media screen and (max-width: 480px){
  flex-direction:column;
}
`

const RoomCarousel=styledComponents(Carousel)`
width:300px;
height:200px;
`

const Price=styledComponents.h2`
font-size:25px;
font-family: 'Poppins', sans-serif;
font-weight:400;
color:black;
`
const SelectBN=styledComponents(Button)`
width:100%;
color:white;
background-color:black;
`
const ContinueBN=styledComponents(Button)`
width:10em;
color:white;
background-color:black;


`

const ChangeButton=styledComponents(Button)`
width:10em;
height:3em;
align-self:center;
margin-right:2em;
color:black;
@media screen and (max-width: 480px){
  margin-bottom:2em;
}

`
const RoomDetailText=styledComponents.p`
font-size:18px;
font-family: 'Poppins', sans-serif;
font-weight:400;
color:black;

`
const BookStepContainer = styledComponents.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  
  `;
/******************styles***************************** */

export function BookStep() {

  const navigate=useNavigate();
  const location=useLocation();
  const checkIn=location.state.checkIn[0];
  const checkOut=location.state.checkIn[1];
  const numOfRooms=location.state.numOfRooms;
  const checkInConv=checkIn.toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"}); 
  const checkOutConv=checkOut.toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"}); 
  const [openedItem, setOpenedItem] = useState(null);
  const [roomTypes,setRoomTypes]=useState([]);
  const enumArr=["Single Room","Deluxe Double Room","Standard Double Room","Twin Room"]

  const Pusher=(str)=>{
    if(roomTypes.length>=numOfRooms){
      roomTypes.pop(1);
    }
    roomTypes.push(str);
  }
  

  



  const arrayMaker=(num)=>{
    let a=[]
    for(let i=0;i<num;i++){
    a.push(i);   
    }
     return a;
  }

  const scrollToNext=(i)=>{
    scroller.scrollTo(`roomCard${i+1}`,{smooth:false,duration:500});

  }
  
  const Continue=()=>{navigate("/Reserve",{state:{checkIn:checkIn
    ,checkOut:checkOut,numOfRooms:numOfRooms,roomType:roomTypes}})}


  const Select=(roomType)=>{navigate("/Reserve",{state:{checkIn:checkIn
    ,checkOut:checkOut,numOfRooms:numOfRooms,roomType:roomTypes}})}

  const MultiSelect=(roomType,i,str)=>{setOpenedItem(str+`${i}`);scrollToNext(i);Pusher(roomType);}  
  return (
    <BookStepContainer>
      <StepContainer>
        <Steps current={1}>
          <Step title={<Stytitle>Dates</Stytitle>} description="" />
          <Step title={<Stytitle>Rooms</Stytitle>} description="" />
          <Step title={<Stytitle>Book</Stytitle>} description="" />
        </Steps>
      </StepContainer>
      <DateCard>
        <CardBody>
          <DescText>Check In Date <br/>{checkInConv}</DescText>
        </CardBody>
        <CardBody>
          <DescText>Check out Date <br/>{checkOutConv}</DescText>
        </CardBody>
        <CardBody>
          <DescText>number of rooms  <br/>{numOfRooms}</DescText>
        </CardBody>
        <ChangeButton outline={true} onClick={()=>navigate("/")}>Change Date</ChangeButton>
      </DateCard>
      <Marginer direction="vertical" margin="3px"/>
      {arrayMaker(numOfRooms).map((i) => (
      <>
      {numOfRooms>1 &&
      <Card style={{margin:"1em"}}>
         <h4>Room {i+1}</h4>
       </Card>
       }
       <Element name={`roomCard${i}`}>
      <RoomCard key={i}>
          <CardBody>
              <RoomCarousel autoPlay infiniteLoop showThumbs={false}>
                <div>
                  <img src={sg1} />
                </div>
                <div>
                  <img src={sg2} />
                </div>
                <div>
                  <img src={sg3} />
                </div>
                <div>
                  <img src={sg4} />
                </div>
                <div>
                  <img src={sg5} />
                </div>
                <div>
                  <img src={sg6} />
                </div>
                <div>
                  <img src={sg7} />
                </div>
                <div>
                  <img src={sg8} />
                </div>
                <div>
                  <img src={sg9} />
                </div>
              </RoomCarousel>
          </CardBody>
          <CardBody>
              <CardTitle><h1>Single Room</h1></CardTitle>
              <RoomDetailText>  with an area of 269 sq ft which can
                  sleep one on a twin bed Room amenities<br/> includes 
                  Free toiletries, 
                  Private bathroom,
                  Shower,
                  Ceiling fan,<br/>
                  Flat-screen TV with
                  Satellite channels
                  Room service,
                  Free WiFi,<br/>
                  Daily housekeeping,
                  Desk</RoomDetailText>
          </CardBody>
          <CardBody>
              <Price>$30</Price><br/>
              {numOfRooms>1?(<SelectBN onClick={()=>{MultiSelect(0,i,"selectSG");}}>{openedItem === `selectSG${i}` ? 'selected' : 'select'}</SelectBN>):( 
              <SelectBN onClick={()=>{Pusher(0);Select(0)}}>Select</SelectBN>)
               }
          </CardBody>
      </RoomCard>
      <RoomCard>
          <CardBody>
          <RoomCarousel  autoPlay infiniteLoop showThumbs={false}>
                <div>
                  <img src={sdr1} />
                </div>
                <div>
                  <img src={sdr2} />
                </div>
                <div>
                  <img src={sdr3} />
                </div>
                <div>
                  <img src={sdr4} />
                </div>
                <div>
                  <img src={sdr5} />
                </div>
              </RoomCarousel>
          </CardBody>
          <CardBody>
              <CardTitle><h1>Standard Double Room</h1></CardTitle>
              <RoomDetailText>  with an area of 323 sq ft which can
                  sleep two on a double bed.<br/> Room amenities includes 
                  Free toiletries, 
                  Private bathroom,
                  Shower,<br/>
                  Ceiling fan,
                  Flat-screen TV with
                  Satellite channels
                  Room service,<br/>
                  Free WiFi,
                  Daily housekeeping,
                  Desk</RoomDetailText>
          </CardBody>
          <CardBody>
          <Price>$30</Price><br/>
          {numOfRooms>1?(<SelectBN onClick={()=>{MultiSelect(2,i,"selectSDR")}}>{openedItem === `selectSDR${i}` ? 'selected' : 'select'}</SelectBN>):( 
              <SelectBN onClick={()=>{Pusher(2);Select(2)}}>Select</SelectBN>)
               }
          </CardBody>
      </RoomCard>
      <RoomCard>
          <CardBody>
          <RoomCarousel autoPlay infiniteLoop showThumbs={false}>
                <div>
                  <img src={ddr1} />
                </div>
                <div>
                  <img src={ddr2} />
                </div>
                <div>
                  <img src={ddr3} />
                </div>
                <div>
                  <img src={ddr4} />
                </div>
                <div>
                  <img src={ddr5} />
                </div>
                <div>
                  <img src={ddr6} />
                </div>
                <div>
                  <img src={ddr7} />
                </div>
                <div>
                  <img src={ddr8} />
                </div>
                <div>
                  <img src={ddr9} />
                </div>
                <div>
                  <img src={ddr10} />
                </div>
              </RoomCarousel>
          </CardBody>
          <CardBody>
              <CardTitle><h1>Deluxe Double Room</h1></CardTitle>
              <RoomDetailText>  with an area of 377 sq ft which can
                  sleep two on a deluxe queen bed <br/> Room amenities includes 
                  Free toiletries, 
                  Private bathroom,
                  Shower,<br/>
                  Ceiling fan,
                  Flat-screen TV  with
                  Satellite channels
                  Room service,
                  Free WiFi,<br/>
                  Daily housekeeping,
                  Desk</RoomDetailText>
          </CardBody>
          <CardBody>
              <Price>$30</Price><br/>
              {numOfRooms>1?(<SelectBN onClick={()=>{MultiSelect(1,i,"selectDDR")}}>{openedItem === `selectDDR${i}` ? 'selected' : 'select'}</SelectBN>):( 
              <SelectBN onClick={()=>{Pusher(1);Select(1)}}>Select</SelectBN>)
               }
          </CardBody>
      </RoomCard>
      <RoomCard>
          <CardBody>
              <RoomCarousel autoPlay infiniteLoop showThumbs={false}>
                <div>
                  <img src={tr1} />
                </div>
                <div>
                  <img src={tr2} />
                </div>
                <div>
                  <img src={tr3} />
                </div>
                <div>
                  <img src={tr4} />
                </div>
                <div>
                  <img src={tr5} />
                </div>
                <div>
                  <img src={tr6} />
                </div>
              </RoomCarousel>
          </CardBody>
          <CardBody>
              <CardTitle><h1>Twin Room</h1></CardTitle>
              <RoomDetailText>  with an area of 377 sq ft which can
                  sleep two on two twin beds <br/> Room amenities includes 
                  Free toiletries, 
                  Private bathroom,
                  Shower,<br/>
                  Ceiling fan,
                  Flat-screen TV with
                  Satellite channels,
                  Room service,
                  Free WiFi,<br/>
                  Daily housekeeping,
                  Desk</RoomDetailText>
          </CardBody>
          <CardBody>
          <Price>$30</Price><br/>
          {numOfRooms>1?(<SelectBN onClick={()=>{MultiSelect(3,i,"selectTR");}}>{openedItem === `selectTR${i}` ? 'selected' : 'select'}</SelectBN>):( 
              <SelectBN onClick={()=>{Pusher(3);Select(3)}}>Select</SelectBN>)
               }
          </CardBody>
      </RoomCard>
      </Element>
      </>
      ))}
      <Element name="continue">
      {
        numOfRooms>1?( 
      <ContinueBN onClick={()=>{Continue();}}>Continue</ContinueBN>):(
        <></>
      )
           }
     </Element>
    </BookStepContainer>
  );
}
