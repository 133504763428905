import React from "react";
import {
  CardGroup,
  Card,
  Row,
  CardTitle,
  CardBody
} from "reactstrap";
import styledComponents from "styled-components";
import { Marginer } from "../marginer";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import sg1 from "../../assets/sg1.webp";
import sg2 from "../../assets/sg2.webp";
import sg3 from "../../assets/sg3.webp";
import sg4 from "../../assets/sg4.webp";
import sg5 from "../../assets/sg5.webp";
import sg6 from "../../assets/sg6.webp";
import sg7 from "../../assets/sg7.webp";
import sg8 from "../../assets/sg8.webp";
import sg9 from "../../assets/sg9.webp";
import tr1 from "../../assets/tr1.webp";
import tr2 from "../../assets/tr2.webp";
import tr3 from "../../assets/tr3.webp";
import tr4 from "../../assets/tr4.webp";
import tr5 from "../../assets/tr5.webp";
import tr6 from "../../assets/tr6.webp";
import sdr1 from "../../assets/sdr1.webp";
import sdr2 from "../../assets/sdr2.webp";
import sdr3 from "../../assets/sdr3.webp";
import sdr4 from "../../assets/sdr4.webp";
import sdr5 from "../../assets/sdr5.webp";
import ddr1 from "../../assets/ddr1.webp";
import ddr2 from "../../assets/ddr2.webp";
import ddr3 from "../../assets/ddr3.webp";
import ddr4 from "../../assets/ddr4.webp";
import ddr5 from "../../assets/ddr5.webp";
import ddr6 from "../../assets/ddr6.webp";
import ddr7 from "../../assets/ddr7.jpg";
import ddr8 from "../../assets/ddr8.webp";
import ddr9 from "../../assets/ddr9.webp";
import ddr10 from "../../assets/ddr10.webp";
/*******************Styles********************/
const RoomDetailsContainer = styledComponents.div`
display:flex;
flex-direction:column;
background-color:whitesmoke;;
`;

const Section1 = styledComponents.div`
display:flex;
flex-direction:column;
`;
const IntroText = styledComponents.div`
padding:2em;
font-family: 'Poppins', sans-serif;
font-size:16px;
font-weight:500;
background-color:white;
width:80%;
align-self:center;
border:2px solid;
border-style: outset;
@media screen and (max-width: 480px){
  font-size:15px;
  background-color:white;
  width:90%;

}
`;
const RoomSection = styledComponents(CardGroup)`
align-self:center;
width:100%;
@media screen and (max-width: 480px){
  
}
`;
const StyTitle = styledComponents(CardTitle)`
margin-top:.5em;
font-family: 'Poppins', sans-serif;
font-size:30px;
font-weight:500;
`;

const SRow = styledComponents(Row)`
width:100%;
justify-content:center;

`;
const Container = styledComponents(Card)`
width:45%;
height:auto;
margin:1em;
@media screen and (max-width: 480px){
  width:100%;
  margin-right:.25px;
  
}
`;
const Header1 = styledComponents.div`
font-family: 'Poppins', sans-serif;
font-size:40px;
font-weight:500;
margin-left:1em;
@media screen and (max-width: 480px){
  align-self:center;
  margin:0px;
  font-size:30px;
font-weight:400;
}
`;
const Details = styledComponents.p`
font-family: 'Poppins', sans-serif;
font-size:17px;
font-weight:300;
`;

/*******************Styles********************/

export function RoomDetails() {
  return (
    <RoomDetailsContainer>
      <Section1>
        <Header1>Rooms</Header1>
        <IntroText>
          Hotel in Kampala with free breakfast and spa services.This hotel
          features a restaurant, a bar/lounge, and a snack bar/deli. Free
          continental breakfast, free WiFi in public areas, and free self
          parking are also provided. Additionally, coffee/tea in a common area,
          spa services, and concierge services are onsite. Diamonds Hotel offers
          60 accommodations with complimentary toiletries and ceiling fans.
          Flat-screen televisions come with satellite channels. Bathrooms
          include showers. Guests can surf the web using the complimentary
          wireless Internet access. Housekeeping is provided daily. Guests can
          pamper themselves by indulging in the onsite spa services.
        </IntroText>
      </Section1>
      <RoomSection>
        <SRow xs={1} md={2}>
          <Container>
            <StyTitle tag="h3" className="text-center">
              Single Room
            </StyTitle>
            <CardBody>
              <Carousel autoPlay infiniteLoop>
                <div>
                  <img src={sg1} />
                </div>
                <div>
                  <img src={sg2} />
                </div>
                <div>
                  <img src={sg3} />
                </div>
                <div>
                  <img src={sg4} />
                </div>
                <div>
                  <img src={sg5} />
                </div>
                <div>
                  <img src={sg6} />
                </div>
                <div>
                  <img src={sg7} />
                </div>
                <div>
                  <img src={sg8} />
                </div>
                <div>
                  <img src={sg9} />
                </div>
              </Carousel>
              <Details>
                <ol>
                  Area: 269 sq ft
                  <br />
                  Sleeps 1 <br />1 Twin Bed
                </ol>

                <h4>Room amenities</h4>
                <ul>
                  Free toiletries <br />
                  Private bathroom <br />
                  Shower
                  <br />
                  Ceiling fan
                  <br />
                  Flat-screen TV
                  <br />
                  Satellite channels
                  <br />
                  Room service
                  <br />
                  Free WiFi
                  <br />
                  Daily housekeeping
                  <br />
                  Desk
                  <br />
                </ul>
              </Details>
            </CardBody>
          </Container>
          <Container>
            <StyTitle tag="h3" className="text-center">
              Deluxe Double Room
            </StyTitle>
            <CardBody>
              <Carousel autoPlay infiniteLoop>
                <div>
                  <img src={ddr1} />
                </div>
                <div>
                  <img src={ddr2} />
                </div>
                <div>
                  <img src={ddr3} />
                </div>
                <div>
                  <img src={ddr4} />
                </div>
                <div>
                  <img src={ddr5} />
                </div>
                <div>
                  <img src={ddr6} />
                </div>
                <div>
                  <img src={ddr7} />
                </div>
                <div>
                  <img src={ddr8} />
                </div>
                <div>
                  <img src={ddr9} />
                </div>
                <div>
                  <img src={ddr10} />
                </div>
              </Carousel>
              <Details>
                <ol>
                  Area: 377 sq ft
                  <br />
                  Sleeps 2 <br />1 Queen Bed
                </ol>

                <h4>Room amenities</h4>
                <ul>
                  Free toiletries <br />
                  Private bathroom <br />
                  Shower
                  <br />
                  Ceiling fan
                  <br />
                  Flat-screen TV
                  <br />
                  Satellite channels
                  <br />
                  Room service
                  <br />
                  Free WiFi
                  <br />
                  Daily housekeeping
                  <br />
                  Desk
                  <br />
                </ul>
              </Details>
            </CardBody>
          </Container>
          <Container>
            <StyTitle tag="h3" className="text-center">
              Standard Double Room
            </StyTitle>
            <CardBody>
              <Carousel>
                <div>
                  <img src={sdr1} />
                </div>
                <div>
                  <img src={sdr2} />
                </div>
                <div>
                  <img src={sdr3} />
                </div>
                <div>
                  <img src={sdr4} />
                </div>
                <div>
                  <img src={sdr5} />
                </div>
              </Carousel>
              <Details>
                <ol>
                  Area: 323 sq ft
                  <br />
                  Sleeps 2 <br />1 Double Bed
                </ol>

                <h4>Room amenities</h4>
                <ul>
                  Free toiletries <br />
                  Private bathroom <br />
                  Shower
                  <br />
                  Ceiling fan
                  <br />
                  Flat-screen TV
                  <br />
                  Satellite channels
                  <br />
                  Room service
                  <br />
                  Free WiFi
                  <br />
                  Daily housekeeping
                  <br />
                  Desk
                  <br />
                </ul>
              </Details>
            </CardBody>
          </Container>
          <Container>
            <StyTitle tag="h3" className="text-center">
              Twin Rooms
            </StyTitle>
            <CardBody>
              <Carousel>
                <div>
                  <img src={tr1} />
                </div>
                <div>
                  <img src={tr2} />
                </div>
                <div>
                  <img src={tr3} />
                </div>
                <div>
                  <img src={tr4} />
                </div>
                <div>
                  <img src={tr5} />
                </div>
                <div>
                  <img src={tr6} />
                </div>
              </Carousel>
              <Details>
                <ol>
                  Area: 377 sq ft
                  <br />
                  Sleeps 2 <br />2 Twin Beds
                </ol>

                <h4>Room amenities</h4>
                <ul>
                  Free toiletries <br />
                  Private bathroom <br />
                  Shower
                  <br />
                  Ceiling fan
                  <br />
                  Flat-screen TV
                  <br />
                  Satellite channels
                  <br />
                  Room service
                  <br />
                  Free WiFi
                  <br />
                  Daily housekeeping
                  <br />
                  Desk
                  <br />
                </ul>
              </Details>
            </CardBody>
          </Container>
        </SRow>
      </RoomSection>
    </RoomDetailsContainer>
  );
}
