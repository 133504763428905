import React from 'react';
import styledComponents from 'styled-components';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import Gal1 from "../../assets/Gal1.jpg"
import Gal2 from "../../assets/Gal2.jpg"
import Gal3 from "../../assets/Gal3.jpg"
import Gal4 from "../../assets/Gal4.jpg"
import Gal7 from "../../assets/Gal7.jpg" 

export function Gallery(){

const StyTitle=styledComponents.h1`
font-family: 'Poppins', sans-serif;
font-size:40px;
font-weight:800;
align-self:center;
@media screen and (max-width: 480px){
    font-weight:500;
    font-size:30px;
    
}
`  
const GalContainer=styledComponents.div`
display:flex;
flex-direction:column;
background-color:whitesmoke;
align-items:center;
`
const GalleryCarousel=styledComponents(Carousel)`

height:604px;
width:1000px;
@media screen and (max-width: 480px){
    width:350px;
    height:500px;
    align-self:center;
}
`



return(
    <GalContainer>
    <StyTitle>Gallery</StyTitle>
    <GalleryCarousel>
    <div>
            <img src={Gal1} />
        </div>
        <div>
            <img src={Gal2} />
        </div>
        <div>
            <img src={Gal3} />
        </div>
        <div>
            <img src={Gal4} />
        </div>
        <div>
            <img src={Gal7} />
        </div>
    </GalleryCarousel>
    </GalContainer>
);




}

